import request from '@/api/request'
import Api from '@/api/index'

export function queryBonus() {
  return Api.check(
      '/api/member/bonus/stat',
      'post'
  )
}

export function queryBonusList(data) {
  return Api.check(
      '/api/member/bonus/list',
      'post',
      data
  )
}

export function withdrawBonus() {
  return Api.check(
      '/api/member/withdraw/stake/bonus',
      'post'
  )
}

























