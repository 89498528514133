<template>
    <div class="root">
        <headers></headers>
        <div class="get-lay max-width-all">
            <div class="left">
                <h1>{{ $t('award.title') }}</h1>
                <p>{{ $t('award.desc') }}</p>
            </div>
            <div class="right">
                <div class="buts">
                    <div>
                        <h3>{{ $t('award.await') }}</h3>
                        <p>
                            <img src="../../static/images/usdt.png" />
                            {{dealAmount(bonusRemain)}} USDT
                        </p>
                    </div>
                    <button v-if="bonusRemain - 0 > 0" @click="withdraw">{{ $t('award.get') }}</button>
					<button v-else class="disabled">{{ $t('award.get') }}</button>
                </div>
                <div class="row">
                    <span>{{ $t('award.all') }}</span>
                    <span>{{dealAmount(bonusTotal)}} USDT</span>
                </div>
                <div class="row">
                    <span>{{ $t('award.yetGet') }}</span>
                    <span>{{dealAmount(bonusTotal - bonusRemain)}} USDT</span>
                </div>
            </div>
        </div>
        <!-- 奖励记录 -->
        <div class="record-title max-width-all">
            <span>
                {{ $t('award.record') }}
            </span>
        </div>
        <!-- 列表 -->
        <div class="list">
            <div v-for="item in bonuses.list" :key="item">
                <div>
                    <span class="date">{{item.date}}</span>
                    <span class="arawd-all">
                        <span>{{ $t('award.allAward') }}</span>
                        <span class="pc">:</span>
                        {{item.total}} USDT</span>
                </div>
                <div>
                    <span class="type">
                        <span>{{ $t('award.type1') }}</span>
                        <span class="pc">:</span>
                        {{item.share}} USDT
                    </span>
                    <span class="type">
                        <span>{{ $t('award.type2') }}</span>
                        <span class="pc">:</span>
                        {{item.team}} USDT
                    </span>
					<span class="type">
                        <span>{{ $t('award.type4') }}</span>
                        <span class="pc">:</span>
                        {{item.same}} USDT
                    </span>
                    <span class="type">
                        <span>{{ $t('award.type3') }}</span>
                        <span class="pc">:</span>
                        {{item.dividend}} USDT
                    </span>
                </div>
            </div>
			<!-- 暂无数据 -->
			<span class="no-data" v-if="bonuses.totalRecord - 0 <= 0">
				<img src="../../static/images/no-data.png" />
			</span>
            <!-- 更多 -->
            <span @click="getNextBonusList" class="more" v-if="bonuses.page < bonuses.totalPage">{{ $t('award.more') }}</span>
        </div>
        <p class="copyright">© 2023 ID LABS. All rights reserved.</p>
    </div>
</template>
<script setup>
    import headers from '@/components/headers'

	import i18n from "@/i18n"
	import { ElMessage } from 'element-plus'

	import {memberStore} from "@/store/member"
	import {walletStore} from "@/store/wallet"
	import {webStore} from "@/store/web"
	import {computed, onMounted, reactive, ref, watch} from "vue"

	import {queryBonus, queryBonusList, withdrawBonus} from '@/api/bonus'
	import Util from "@/utils/common-util";
	import {start, end} from "@/api/loading";
	import {send} from "@/contract/web3-util";
	import Web3 from "web3";

	const mStore = memberStore()
	const wStore = walletStore()
	const wbStore = webStore()

	const bonusTotal = ref(0)
	const bonusRemain = ref(0)

	const bonuses = reactive({
		page: 1,
		pageSize: 0,
		totalPage: 0,
		totalRecord: 0,
		list: [],
	})

	const withdraw = () => {
		if (!wbStore.isLogin()) {
			ElMessage({
				message: i18n.global.t('alert.connectWallet'),
				type: 'warning'
			})
			return
		}

		if (bonusRemain.value <= 0) {
			return
		}

		start()
		withdrawBonus().then(result => {
			if (Util.isDefine(result) && result.data.code === 1) {
				const token = result.data.data.token
				const orderSerial = result.data.data.orderSerial
				const bonus = result.data.data.amount + ''
				const signTime = result.data.data.signTime
				const sign = result.data.data.sign

				send('stake', 'withdrawBonus', [token, Web3.utils.toWei(bonus), signTime, orderSerial, sign]).then(receipt => {

					if (receipt.status) {
						ElMessage({
							message: i18n.global.t('alert.success'),
							type: 'success'
						})

						getBonus()
					} else {
						ElMessage({
							message: i18n.global.t('alert.failed'),
							type: 'error'
						})
					}
					end()
				}).catch(function(error) {
					console.error(error)
					end()
					ElMessage({
						message: i18n.global.t('alert.failed'),
						type: 'error'
					})
				})
			}
		}).catch(function(error) {
			console.error(error)
			end()
		})
	}

	function getBonus() {
		if (!wbStore.isLogin()) {
			return
		}

		queryBonus().then((result) => {
			if (Util.isDefine(result) && result.data.code === 1) {
				bonusTotal.value = result.data.data.stakeBonusTotal - 0
				bonusRemain.value = result.data.data.stakeBonusRemain - 0
			}
		})
	}

	function getBonusList() {
		if (!wbStore.isLogin()) {
			return
		}

		bonuses.list = []
		bonuses.page = 1
		bonuses.pageSize = 0
		bonuses.totalPage = 0
		bonuses.totalRecord = 0
		getBonuses()
	}
	function getNextBonusList() {
		if (!wbStore.isLogin()) {
			return
		}

		if (bonuses.page < bonuses.totalPage) {
			bonuses.page++
		} else {
			return
		}

		getBonuses()
	}

	function getBonuses() {
		if (!wbStore.isLogin()) {
			return
		}

		queryBonusList({page: bonuses.page, pageSize: 6}).then(function (result) {
			if (Util.isDefine(result) && result.data.code === 1) {
				const data = result.data.data.pagingList
				bonuses.page = data.currPage
				bonuses.pageSize = data.pageSize
				bonuses.totalPage = data.totalPage
				bonuses.totalRecord = data.totalRecord

				const list = data.resultList
				for (let i = 0; Util.isDefine(list) && i < list.length; i++) {
					const a = list[i]
					const bonus = {}
					// bonus.date = a.date
					bonus.date = new Intl.DateTimeFormat('en-US', { dateStyle: 'long' }).format(new Date(Date.parse(a.date)))
					bonus.share = Util.numFormat4Floor(a.share - 0)
					bonus.team = Util.numFormat4Floor(a.team - 0)
					bonus.same = Util.numFormat4Floor(a.bonus - a.share - a.team - a.dividend)
					bonus.dividend = Util.numFormat4Floor(a.dividend - 0)
					bonus.total = Util.numFormat4Floor(a.bonus - 0)

					bonuses.list.push(bonus)
				}
			}
		})
	}

	function dealAmount(amount, decimals) {
		if (decimals && decimals === 6) {
			return Util.numFormat6Floor(amount)
		}
		return Util.numFormat4Floor(amount)
	}

	onMounted(() => {
		getBonus()
		getBonusList()
	})

	const curAccount = computed(()=>{
		return wStore.curAccount
	})
	watch(curAccount, (newVal, oldVal) =>{
		if (newVal) {
			if (oldVal) {
				alert(i18n.global.t('alert.reLogin'))
			}
			wbStore.accountLogin().then((result) => {
				wbStore.setLoginState(true)
				mStore.queryMemberInfo()

				getBonus()
				getBonusList()
			}).catch((reason) => {
				wbStore.setLoginState(false)
				console.log(reason)
				console.error('login failed')
			})
		}
	})

</script>
<style scoped>
    .root{
        color: white;
        padding-bottom: 20px;
        background: linear-gradient(90deg, #172929 0%, #0D1113 50%, #0E152B 100%);
    }
    .get-lay{
        width: calc(100% - 320px);
        margin: 100px auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .get-lay .left p{
        width: 400px;
        font-size: 12px;
        color: #BECFD2;
    }
    .get-lay .right{
        width: 400px;
        color: black;
        padding: 2px 24px 20px 24px;
        background-color: #E9FFF7;
        border-radius: 24px;
    }
    .get-lay .right .buts{
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid rgba(13,17,19,0.1);
    }
    .get-lay .right .buts div p{
        display: flex;
        align-items: center;
        font-weight: bold;
    }
    .get-lay button{
        width: 100px;
        height: 40px;
        color: black;
        font-weight: bold;
        background-color: #20EEA4;
        border-radius: 8px;
    }
	.get-lay button.disabled {
		background-color: #dddddd;
		color: #999999;
	}
    .get-lay img{
        width: 30px;
        margin-right: 5px;
    }
    .get-lay .right .row{
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 12px;
        margin-top: 10px;
        color: rgba(13,17,19,0.75);
    }
    .get-lay .right .row span:last-child{
        color: #0D1113;
    }
    .record-title{
        width: calc(100% - 320px);
        margin: 0 auto;
    }
    .record-title span{
        font-size: 18px;
        font-weight: bold;
        background: linear-gradient(to left, #40FFF4 0%, #FFFFFF 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        border-bottom: 3px solid #20EEA4;
        padding-bottom: 5px;
    }
    .list{
        background-color: #1C282C;
        padding: 56px 160px;
        margin-top: 8px;
    }

    .list > div{
        max-width: calc(1200px - 48px);
        margin: 0 auto 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 24px;
        border-radius: 12px;
        color: black;
        background-color: #E9FFF7;
    }
    .list > div div:first-child{
        display: flex;
        flex-direction: column;
        font-weight: bold;
    }
    .list .date{
        color: #00ABAB;
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 10px;
    }
    .list > div div:last-child{
        display: flex;
        flex-direction: column;
        font-size: 12px;
        color: rgba(13,17,19,0.75);
    }
    .list > div div:last-child span{
        margin-bottom: 5px;
    }
    .list > div div:last-child span:last-child{
        margin-bottom: 0
    }
    @media screen and (max-width: 900px) {
        .pc{
            display: none;
        }
        .get-lay{
            width: calc(100% - 64px);
            margin: 30px auto 48px;
            flex-direction: column;
            align-items: flex-start;
        }
        .get-lay .left p{
            margin-bottom: 20px;
        }
        .get-lay .right{
            width: calc(100% - 64px);
        }
        .get-lay .right button{
            width: 80px;
        }
        .get-lay img{
            width: 24px;
        }
        .record-title{
            width: calc(100% - 64px);
        }
        .list{
            padding: 45px 32px;
        }
        .list > div{
            flex-direction: column;
            align-items: flex-start;
        }
        .list > div div:first-child{
            width: 100%;
            margin-bottom: 20px;
        }
        .list .arawd-all{
            width: 100%;
            display: flex;
            justify-content: space-between;
        }
        .list > div div:last-child{
            width: 100%;
        }
        .list .type{
            display: flex;
            justify-content: space-between;
        }
    }
</style>
